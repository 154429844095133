var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-2 mb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"}),_c('div',{staticClass:"col-lg-6 col-md-12 pr-lg-1 mb-lg-0 mb-2"},[_c('b-card',{staticClass:"details"},[_c('div',{staticClass:"header mb-3 d-flex justify-content-between"},[_c('h5',[_vm._v("Item Information")]),_c('i',{staticClass:"fa fa-info-circle info-icon text-secondary",attrs:{"id":"basicinfo"}},[_c('b-tooltip',{attrs:{"target":"basicinfo","triggers":"hover","placement":"righttop"}},[_c('div',[_vm._v("Pricing Details")]),_c('p',[_vm._v("Enter pricing details of item, equipment or any product")])])],1)]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Name),expression:"currentDataObj.details.Name"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-pricing-name","type":"text"},domProps:{"value":(_vm.currentDataObj.details.Name)},on:{"keyup":function($event){return _vm.updateField(
                    'Name', //PropertyName
                    _vm.currentDataObj.details.Name, //Property Value
                    'Item Name', //Display Name,
                    _vm.currentDataObj.details.Name, //Display Value
                    'name' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "Name", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Sale Price")]),_c('div',{staticClass:"input-group input-with-dollar-sign"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v("$")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.SalePrice),expression:"currentDataObj.details.SalePrice"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-pricing-sale-price","type":"number"},domProps:{"value":(_vm.currentDataObj.details.SalePrice)},on:{"keyup":function($event){return _vm.updateField(
                      'SalePrice', //PropertyName
                      _vm.currentDataObj.details.SalePrice, //Property Value
                      'Price', //Display Name,
                      _vm.currentDataObj.details.SalePrice, //Display Value
                      'salePrice' //Data object Property Name
                    )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "SalePrice", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Cost Price")]),_c('div',{staticClass:"input-group input-with-dollar-sign"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v("$")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.ItemCost),expression:"currentDataObj.details.ItemCost"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-pricing-cost-price","type":"number"},domProps:{"value":(_vm.currentDataObj.details.ItemCost)},on:{"keyup":function($event){return _vm.updateField(
                      'ItemCost', //PropertyName
                      _vm.currentDataObj.details.ItemCost, //Property Value
                      'Cost', //Display Name,
                      _vm.currentDataObj.details.ItemCost, //Display Value
                      'itemCost' //Data object Property Name
                    )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "ItemCost", $event.target.value)}}})])])])])])],1),_c('div',{staticClass:"col-lg-6 col-md-12 pl-lg-1"},[_c('b-card',{staticClass:"details"},[_c('div',{staticClass:"header mb-3 d-flex justify-content-between"},[_c('h5',[_vm._v("Stock Information")]),_c('i',{staticClass:"fa fa-info-circle info-icon text-secondary",attrs:{"id":"pcontact"}},[_c('b-tooltip',{attrs:{"target":"pcontact","triggers":"hover","placement":"righttop"}},[_c('div',[_vm._v("Stock Info")]),_c('p',[_vm._v("Enter the stock details")])])],1)]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mb-2"},[_c('span',{staticClass:"c-toolbar__state-title u-text-capitalize"},[_vm._v("Quantity")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDataObj.details.Qty),expression:"currentDataObj.details.Qty"}],staticClass:"form-control",attrs:{"type":"number","id":"slidepanel-edit-pricing-quantity"},domProps:{"value":(_vm.currentDataObj.details.Qty)},on:{"keyup":function($event){return _vm.updateField(
                    'Qty', //PropertyName
                    _vm.currentDataObj.details.Qty, //Property Value
                    'Qty on Hand', //Display Name,
                    _vm.currentDataObj.details.Qty, //Display Value
                    'qty' //Data object Property Name
                  )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentDataObj.details, "Qty", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6  mb-2"},[_c('span',{staticClass:"c-toolbar__state-title "},[_vm._v("Labor Hours Per Unit (HH/mm)")]),_c('div',{staticClass:"d-flex"},[_c('input',{staticClass:"form-control radius-right-0 border-right-zero",attrs:{"id":"slidepanel-edit-pricing-labor-hours","type":"number"}}),_c('input',{staticClass:"form-control radius-left-0",attrs:{"id":"slidepanel-edit-pricing-labor-minutes","type":"number"}})])]),_c('div',{staticClass:"col-md-6 mb-2"},[_c('global-settings-modal',{attrs:{"id":"slidepanel-edit-pricing-global-setting-account","label":"Account","actionId":_vm.MODULE.CLIENTS.UX.Account}}),_c('v-select',{attrs:{"options":_vm.UXData.uxAccount,"id":"slidepanel-edit-pricing-account","disabled":_vm.isDisabled,"value":_vm.getSelectedDropDownValue('uxAccount', 'IntAccountsID'),"label":"displayName"},on:{"input":function($event){return _vm.onDropdownSelectionChange(
                    $event,
                    'Account', //Display Name
                    'uxAccount', //Dropdown List Object
                    'IntAccountsID' //Edit Details Property Name
                  )}}})],1),_c('div',{staticClass:"col-md-6 mb-2"},[_c('global-settings-modal',{attrs:{"id":"slidepanel-edit-pricing-group","label":"Group","actionId":_vm.MODULE.CLIENTS.UX.Group}}),_c('v-select',{attrs:{"options":_vm.UXData.uxGroup,"id":"slidepanel-edit-pricing-group","disabled":_vm.isDisabled,"value":_vm.getSelectedDropDownValue('uxGroup', 'IntGroupID'),"label":"displayName"},on:{"input":function($event){return _vm.onDropdownSelectionChange(
                    $event,
                    'Group', //Display Name
                    'uxGroup', //Dropdown List Object
                    'IntGroupID' //Edit Details Property Name
                  )}}})],1)])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }