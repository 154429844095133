<template>
  <div class="mx-2 mb-2">
    <div class="row">
      <div class="col-md-12">
        <!-- <b-alert show class="mb-2" variant="danger" v-if="hasRelatedJob">
        <i class="fa fa-check-circle mr-2"></i>
        <span class="sub-title mr-2"
          >
            Item is currently out of stock.
                   
          </span
        >
      </b-alert> -->
      </div>
      <div class="col-lg-6 col-md-12 pr-lg-1 mb-lg-0 mb-2">
        <b-card class="details">
          <div class="header mb-3 d-flex justify-content-between">
            <h5>Item Information</h5>
            <i class="fa fa-info-circle info-icon text-secondary" id="basicinfo">
              <b-tooltip target="basicinfo" triggers="hover" placement="righttop">
                <div>Pricing Details</div>
                <p>Enter pricing details of item, equipment or any product</p>
              </b-tooltip>
            </i>
          </div>
          <div class="content">
            <div class="row">
              <div class="col-md-12 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Name</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-pricing-name"
                  type="text"
                  v-model="currentDataObj.details.Name"
                  @keyup="
                    updateField(
                      'Name', //PropertyName
                      currentDataObj.details.Name, //Property Value
                      'Item Name', //Display Name,
                      currentDataObj.details.Name, //Display Value
                      'name' //Data object Property Name
                    )
                  "
                />
              </div>
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Sale Price</span>
                <div class="input-group input-with-dollar-sign">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input
                    class="form-control"
                    id="slidepanel-edit-pricing-sale-price"
                    type="number"
                    v-model="currentDataObj.details.SalePrice"
                    @keyup="
                      updateField(
                        'SalePrice', //PropertyName
                        currentDataObj.details.SalePrice, //Property Value
                        'Price', //Display Name,
                        currentDataObj.details.SalePrice, //Display Value
                        'salePrice' //Data object Property Name
                      )
                    "
                  />
                </div>
              </div>
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Cost Price</span>
                <div class="input-group input-with-dollar-sign">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input
                    class="form-control"
                    id="slidepanel-edit-pricing-cost-price"
                    type="number"
                    v-model="currentDataObj.details.ItemCost"
                    @keyup="
                      updateField(
                        'ItemCost', //PropertyName
                        currentDataObj.details.ItemCost, //Property Value
                        'Cost', //Display Name,
                        currentDataObj.details.ItemCost, //Display Value
                        'itemCost' //Data object Property Name
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>

      <div class="col-lg-6 col-md-12 pl-lg-1">
        <b-card class="details">
          <div class="header mb-3 d-flex justify-content-between">
            <h5>Stock Information</h5>
            <i class="fa fa-info-circle info-icon text-secondary" id="pcontact">
              <b-tooltip target="pcontact" triggers="hover" placement="righttop">
                <div>Stock Info</div>
                <p>Enter the stock details</p>
              </b-tooltip>
            </i>
          </div>
          <div class="content">
            <div class="row">
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Quantity</span>
                <input
                  class="form-control"
                  type="number"
                  id="slidepanel-edit-pricing-quantity"
                  v-model="currentDataObj.details.Qty"
                  @keyup="
                    updateField(
                      'Qty', //PropertyName
                      currentDataObj.details.Qty, //Property Value
                      'Qty on Hand', //Display Name,
                      currentDataObj.details.Qty, //Display Value
                      'qty' //Data object Property Name
                    )
                  "
                />
              </div>
              <div class="col-md-6  mb-2">
                <span class="c-toolbar__state-title ">Labor Hours Per Unit (HH/mm)</span>
                <div class="d-flex">
                  <input class="form-control radius-right-0 border-right-zero" id="slidepanel-edit-pricing-labor-hours" type="number" />
                  <input class="form-control radius-left-0" id="slidepanel-edit-pricing-labor-minutes" type="number" />
                </div>
              </div>

              <div class="col-md-6 mb-2">
                <!-- <span class="c-toolbar__state-title u-text-capitalize">Account</span> -->
                <global-settings-modal id="slidepanel-edit-pricing-global-setting-account" label="Account" :actionId="MODULE.CLIENTS.UX.Account" />
                <v-select
                  :options="UXData.uxAccount"
                  id="slidepanel-edit-pricing-account"
                  :disabled="isDisabled"
                  :value="getSelectedDropDownValue('uxAccount', 'IntAccountsID')"
                  @input="
                    onDropdownSelectionChange(
                      $event,
                      'Account', //Display Name
                      'uxAccount', //Dropdown List Object
                      'IntAccountsID' //Edit Details Property Name
                    )
                  "
                  label="displayName"
                ></v-select>
              </div>

              <div class="col-md-6 mb-2">
                <!-- <span class="c-toolbar__state-title u-text-capitalize">Group</span> -->
                <global-settings-modal id="slidepanel-edit-pricing-group" label="Group" :actionId="MODULE.CLIENTS.UX.Group" />
                <v-select
                  :options="UXData.uxGroup"
                  id="slidepanel-edit-pricing-group"
                  :disabled="isDisabled"
                  :value="getSelectedDropDownValue('uxGroup', 'IntGroupID')"
                  @input="
                    onDropdownSelectionChange(
                      $event,
                      'Group', //Display Name
                      'uxGroup', //Dropdown List Object
                      'IntGroupID' //Edit Details Property Name
                    )
                  "
                  label="displayName"
                ></v-select>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import editMixin from "@/mixin/edit-mixin";

  export default {
    mixins: [editMixin],
  };
</script>
